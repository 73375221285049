import {Link } from 'gatsby';
import React, {useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import {connect} from 'react-redux';

import {getTotalListFilters} from '../redux/propertiesDucks';
import {getDevelopmentsAction} from '../redux/developmentsDucks';

import {makeItemsNav} from '../helpers/helper.rendering'
import {getOperationView} from '../helpers/helper.filters'

import { getNoveltiesAction } from '../redux/noveltiesDucks';
import { element } from 'prop-types';

const MenuNav = ({filters,curFilters,api_key,developments,novelties,dispatch,setOpen}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              global_email
              social{
                facebook
                instagram
                linkedin
                youtube
                twitter
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                  footer {
                      title_contact
                      title_social
                      title_newsletter
                      title_office
                      button_contact{
                          link
                          value
                      }
                  }
              }
          }
    }`)

    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction())
            dispatch(getTotalListFilters())
        }
    }, [api_key])
    
    useEffect(() => {
        dispatch(getNoveltiesAction())
    },[])

    const staticBody = () => {
        var body = document.body;
        body.classList.remove("overflow-hidden");
    }

    const isResultView = () => {
        if(pathname.includes('venta') || pathname.includes('alquiler')){
            return true;
        }
        return false
    }

    // const makeItemsNav = () => {
    //     const items = [
    //         {name:"HOME",link:"/"},
    //         {name:"EMPRENDIMIENTOS",link:"/emprendimientos/"},
    //         {name:"VENTA",link:"/venta/"},
    //         {name:"ALQUILER",link:"/alquiler/"},
    //         {name:"MIAMI",link:"https://www.capitalm.us/",blank:true},
    //         {name:"NOSOTROS",link:"/nosotros/"},
    //         // {name:"NOVEDADES",link:"/novedades/"},
    //         {name:"CONTACTO",link:"/contacto/"},
    //     ]
    //     if(!existDevelopment(developments)){
    //         items.splice(1,1)
    //     }
    //     if(!existOperation("venta",filters)){
    //         items.splice(2,1)
    //     }
    //     if(!existOperation("alquiler",filters)){
    //         items.splice(3,1)
    //     }
    //     return items
    // }


    return realEstate.sections ? (
        <nav className="nav-content opened d-flex align-items-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 pe-xl-5">
                    {/* <div className="col-lg-8 px-xl-5 justify-content-center d-flex flex-column"> */}
                        <ul className="nav-menu">
                            {makeItemsNav(developments,filters).map((item,index) => (
                                <li className={(pathname === item.link.replaceAll("/","").toLowerCase() ? 'active ' : '')}>
                                    {
                                        item.blank ? 
                                            <a target="_blank" onClick={() => staticBody() + setOpen(false)} className="" href={item.link}>{item.name} <span>0{index+1}</span></a>
                                        :
                                            <Link onClick={() => staticBody() + setOpen(false)} className="" to={item.link}>{item.name} <span>0{index+1}</span></Link>
                                    }
                                </li>
                            ))}
                            {/* <li className={(pathname === '' ? 'active ' : '')}><Link onClick={() => staticBody()} className="" to="/">HOME <span>01</span></Link></li> */}
                            {/* {existDevelopment(developments) ? <li className={(pathname === 'emprendimientos' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/emprendimientos/">EMPRENDIMIENTOS<span>02</span></Link></li>:''} */}
                            {/* {existOperation("venta",filters) ? <li className={(getOperationView(curFilters) === 'venta' && isResultView()  ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/venta/">VENTA<span>02</span></Link></li>:''} */}
                            {/* {existOperation("alquiler",filters) ? <li className={(getOperationView(curFilters) === 'alquiler' && isResultView() ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/alquiler/">ALQUILER<span>03</span></Link></li>:''} */}
                            {/* <li className={(pathname === 'servicios' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/servicios/">SERVICIOS<span>05</span></Link></li> */}
                            {/* <li className={(pathname === 'servicios' ? 'active ' : '')}><a  onClick={() => staticBody()} className="" href='https://www.capitalm.us/' target="_blank">MIAMI<span>04</span></a></li> */}
                            {/* <li className={(pathname === 'nosotros' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/nosotros/">NOSOTROS<span>05</span></Link></li> */}
                            {/* <li className={(novelties.length === 0 && ' d-none ') + (pathname === 'novedades' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/novedades/">NOVEDADES<span>06</span></Link></li> */}
                            {/* <li className={(pathname === 'contacto' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="mb-0" to="/contacto/">CONTACTO<span>06</span></Link></li> */}
                        </ul>
                    </div>
                    <div className="col-lg-4 px-xl-5 contact-nav">
                        <div className="row">
                            <div class="item-contact col-8 col-lg-12">
                                <h5>{realEstate.sections.footer.title_contact}</h5>
                                <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                            </div>
                            <div className="col-12 max-heigth-scroll">
                                {realEstate.branch_office.map((item,index) => (
                                    <div class="item-contact d-none d-lg-block col-12">
                                        <h5  className="col-lg-12 col-4">{realEstate.sections?.footer?.title_office} {" "} {item.region}</h5>
                                        <p >{item.name}</p>
                                        <p >{item.address}</p>
                                        <p >{item.city}</p>
                                        {item.contact.phone.map(phone => (
                                            <p >Teléfono: {phone}</p>
                                        ))}
                                    </div>
                                ))} 
                            </div>
                            <div class="item-contact social col-4 col-lg-4">
                                <h5>{realEstate.sections.footer.title_social}</h5> 
                                <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                                <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                                <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
                                <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                                <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    ):''
}

export default connect(state => ({
    filters:state.properties.totalListFilters,
    curFilters:state.properties.filters,
    novelties:state.novelties.novelties,
    api_key:state.settings.keys.tokko,
    developments:state.developments.developments
}),null)(MenuNav);