import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { connect } from "react-redux"

import Header from "./header"
import Footer from "./footer"
import { useEffect } from "react"

import {CHANGE_APIKEY_ACTION,CHANGE_MAPKEY_ACTION} from '../redux/settingsDucks'

const Layout = ({ dispatch,children,serverData }) => {
  const data = useStaticQuery(graphql`
    query LayoutInfoQuery {
      realEstate {
        seo{
          title
        }
        logo
        colors {
          primaryColor
          secondaryColor
          tertiaryColor
        }
        keys {
          tokko
          google_map
        }
      }
    }
  `)

  useEffect(() => {
      dispatch(CHANGE_APIKEY_ACTION(data?.realEstate?.keys?.tokko))
      dispatch(CHANGE_MAPKEY_ACTION(data?.realEstate?.keys?.google_map))
  },[data.realEstate])


  return (
    <>
      <Helmet>
        <style>
        {`\
          :root{\
            --primaryColor: ${data.realEstate.colors.primaryColor};\
            --secondaryColor: ${data.realEstate.colors.secondaryColor};\
            --tertiaryColor: ${data.realEstate.colors.tertiaryColor};\
          }\
        `}
        </style>
      </Helmet>
      <Header siteTitle={data.realEstate.seo?.title || `Title`} logo={data.realEstate.logo} />
        <main>{children}</main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
}),null)(Layout);

export async function getServerData() {
  try {
    const res = await fetch(`https://dog.ceo/api/breeds/image/random`)
    if (!res.ok) {
      throw new Error(`Response failed`)
    }
    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {}
    }
  }
}
