import { data } from "jquery";

export const getCover = (photos) => {
    if(photos?.length > 0){
        if(photos.find(photo => photo.is_front_cover)){
            return photos.find(photo => photo.is_front_cover);
        }
        else{
            return photos[0];
        }
    }
    return {
        original:"https://mediahaus.com.ar/noimage.jpg",
        image:"https://mediahaus.com.ar/noimage.jpg"
    }
}

export const getPreviewSlider = (photos) => {
    let array=[];
    if(photos){
        if(photos.length >= 5){
            array = photos.slice(0,3)
        }
        array[0] = getCover(photos)
    }
    return array
}

export const getPhotos = (data) => {
    if(data?.photos?.length > 0){
        return data.photos.filter(photo => !photo.is_blueprint)
    }
    return  [{
                original:"https://mediahaus.com.ar/noimage.jpg",
                image:"https://mediahaus.com.ar/noimage.jpg",
            }]
} 

export const getDescription = (data,characters,notData = false) => {
    if(data.description ){
        if(data.description.length === 0)  return 'Sin descripción'
        const description = data.description.replaceAll("&nbsp;","").slice(0,characters).split("\n")
        return description
    }
    return ['Sin descripción']
}

export const descriptionIsNull = (data) => {
    if(data.description){
        if(data.description.length === 0)  return true
    }
    return false
}

export const getBrText = (text) => {
    const description = text.split("\n")
    return description
}

export const existDevelopment = (developments) => {
    if(developments?.data?.objects?.length > 0){
        return true
    }
    return false
}

export const existOperation = (operation,filters) => {
    switch(operation){
        case 'venta':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 1)) 
                return true
            break;
        case 'alquiler':
            if(filters?.data?.objects?.operation_types.find(operation => operation.operation_type === 2))
                return true
            break;
    }
    return false
}

export const getMessageWhatsapp = (development) => {
    if(development?.name){
      return 'Quería consultar sobre el emprendimiento ' + development?.name
    }
    else {
      return "¡Hola!"
    }
}

export const existArray = (array) => {
    console.log(array.find(array => array === '').length)
    if(array.find(array => array === '')){
        return false
    }
    return true;
}

export const getPhonesText = (phones) => {
    let text = ''
    if(phones){
        phones.forEach((phone,index) => {
            text += phone + (index === phones.length - 1 ? '' : ' / ')
        })
    }
    return text
}

export const getQuality = (photo) => {
    return photo?.image
}

export const getSeoDescription = (url) => {
    
}

export const getPriceForMarker = (price) => {
    const value = price.price

    let response = price.currency === 'USD' ? `USD ` : `ARS `
    if(value?.toString().toLowerCase() === 'precio a consultar'){return 'CONSULTAR'}
    //transform 100.000 to 100k and 1.000.000 to 1m
    if(value > 0){
      if(value >= 1000000){
        if(Number.isInteger(value/1000000)){
          response += `${value/1000000}M`
        }
        else{
          response += `${(value/1000000).toFixed(2)}M`
        }
      }else if(value >= 1000){
        if(Number.isInteger(value/1000)){
          response += `${(value/1000).toFixed(0)}K`
        }
        else{
          response += `${(value/1000).toFixed(2)}K`
        }
      }else{
        response += `${value}`
      }
    }
    return response
}

export const makeItemsNav = (developments,filters) => {
    const items = [
        {name:"HOME",link:"/"},
        {name:"VENTA",link:"/venta/"},
        {name:"ALQUILER",link:"/alquiler/"},
        {name:"HORIZONS",link:"/emprendimientos/32939"},
        {name:"MIAMI",link:"https://www.capitalm.us/",blank:true},
        {name:"NOSOTROS",link:"/nosotros/"},
        // {name:"NOVEDADES",link:"/novedades/"},
        {name:"CONTACTO",link:"/contacto/"},
    ]
    if(!existDevelopment(developments)){
        items.splice(1,1)
    }
    if(!existOperation("venta",filters)){
        items.splice(2,1)
    }
    if(!existOperation("alquiler",filters)){
        items.splice(3,1)
    }
    return items
}